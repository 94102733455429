import React from 'react'
import styles from './OsForMobility.module.css';
import Navbar1 from '../../Components/Navbar1/Navbar1';
import Footer from '../../Components/Footer/Footer';
// import { Link } from 'react-router-dom';
import { useState,useEffect } from 'react';
import TechCard from '../../Components/TechCard/TechCard';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const logos = [
    { name: 'whistledrive-invesco-logo', id: 1 },
    { name: 'whistledrive-novartis-logo', id: 2 },
    { name: 'whistledrive-one-plus-logo', id: 3 },
    { name: 'whistledrive-examity-logo', id: 4 },
    { name: 'whistledrive-L&T-logo', id: 5 },
    { name: 'whistledrive-ADP-logo', id: 6 },
]

const TechCardContent = [
    {
        img: 'Employee', heading: 'Employees',
        description: [
            { text: 'Smart Mobile Application', id: 1 },
            { text: 'Live Tracking', id: 2 },
            { text: 'Advanced Safety & Security', id: 3 },
            { text: 'Ad-hoc Request feature', id: 4 },
        ], id: 1
    },
    {
        img: 'client-admin', heading: 'Client Admin Team',
        description: [
            { text: 'Eagle Eye View', id: 1 },
            { text: 'Detailed Report & Billing', id: 2 },
            { text: 'Cab Driver & Compliance', id: 3 },
            { text: 'Ad-hoc Request feature', id: 4 },
        ], id: 2
    },
    {
        img: 'vendors', heading: 'Transportation Vendors',
        description: [
            { text: ' Mobile & Web Application', id: 1 },
            { text: ' Ease of Fleet Management', id: 2 },
            { text: 'Customized Reports & Billing', id: 3 },
        ], id: 3
    },
    {
        img: 'Supervisor', heading: 'Supervisor',
        description: [
            { text: '  Mobile & Web Application', id: 1 },
            { text: ' Ease of Fleet Management', id: 2 },
            { text: ' Ad-hoc Flow', id: 3 },
            { text: 'Ad-hoc Request feature', id: 4 },
        ], id: 4
    },
    {
        img: 'Driver', heading: 'Drivers',
        description: [
            { text: ' Easy to use Application', id: 1 },
            { text: ' Transparent Trip Data', id: 2 },
            { text: ' 24/7 Support & Safety', id: 3 },
            { text: 'Automated Alert on Trips & Document Expiry', id: 4 },
        ], id: 5
    },
]

function OsForMobility() {

    const [radiobtn, setRadiobtn] = useState<any>("");
    // const [fullControl, setFullControl] = useState<any>("");
    const [controlImg, setControlImg] = useState<any>("");
    const [groundImg, setGroundImg] = useState<any>("")

    // const handleControl = (text: any, img: any) => {
    //     setFullControl(text)
    //     setControlImg(img)
    //     console.log("HandleTrigger")
    // }

    useEffect(() => {
        setRadiobtn("SF")
        setControlImg("Live-Tracking")
        setGroundImg("TripModifications")

    }, [])


    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>MobilityOS | Corporate Fleet Management Software</title>
                <meta
                    name="description"
                    content="Providing comprehensive fleet management solutions that enable your business to reduce costs, improve safety, efficiency and ensuring compliance at all levels."
                />
            </Helmet>
            <Navbar1 />
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.subContent}>
                            <h5><span>WhistleOS</span> for Employee Transportation</h5>
                            <h1 className={styles.fullStack}>A Complete Employee Transportation Management Software</h1>
                            <p className={styles.paragraph}>WhistleOS helps in automating most of the processes involved in the Employee Commute</p>
                            <Link to="/contact-us">Contact Us</Link>
                        </div>
                        <div className={styles.mainImgContainer}>
                            <img src='../assests/images/OsForMobility/Os-For-Mobility.png' alt='os-for-mobility' />
                        </div>

                        {/* <div className={styles.videoContainer}>
                            <video autoPlay loop muted style={{ width: '100%' }}>
                                <source src="../assests/images/home-page gif.mp4" type="video/mp4" />
                            </video>
                        </div> */}

                    </div>
                    <div className={styles.brandContainer}>
                        <div className={styles.brandImgsContainer}>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name} />
                                ))}
                            </div>
                            <div className={styles.brandLogoCon}>
                                {logos.map((eachItem: any) => (
                                    <img src={`../assests/images/${eachItem.name}.png`} key={eachItem.id} alt={eachItem.name}/>
                                ))}
                            </div>

                        </div>
                    </div>
                    <hr className={styles.line1} />
                    <div className={styles.whistleTechContainer}>
                        <div className={styles.techContent}>
                            <h3>WhistleDrive’s Tech – What’s in it for you?</h3>
                            <div className={styles.techContentCard}>
                                {TechCardContent.map((eachItem) => (<TechCard content={eachItem} key={eachItem.id} />))}
                            </div>

                        </div>

                    </div>
                    <div className={styles.ensuringSafetyContainer}>
                        <div className={styles.ensuringSafetyContent}>
                            <h2>Ensuring Safety, Security and Support in every trip</h2>
                            <div
                                className={styles.hover}
                                style={radiobtn === "SF" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setRadiobtn("SF")
                                }}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${radiobtn === "SF" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Safety Features
                                </h3>
                                {radiobtn === "SF" && (<p className={styles.para}>
                                    With advanced safety features like Number Masking, Emergency Alerts, Location Sharing and Safe Drop confirmation, and dedicated customer support 24/7, Employees can now travel with Confidence!
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={radiobtn === "TT" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setRadiobtn("TT")
                                }}

                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${radiobtn === "TT" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Trip Tracking
                                </h3>
                                {radiobtn === "TT" && (<p className={styles.para}>
                                    The Employee application provides real-time updates on their driver's location and also the estimated arrival time based on the current traffic. This helps the employees in planning their commute with reduced waiting time and improved satisfaction.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={radiobtn === "TC" ? { backgroundColor: '#f8f9fb' } : {}}
                                // onClick={() => setRadiobtn("TC")}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setRadiobtn("TC")
                                }}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${radiobtn === "TC" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Trip Communications
                                </h3>
                                {radiobtn === "TC" && (<p className={styles.para}>
                                    Alerts on every important trip update. All the communications between driver, employee, and supervisor are made in-app for employee privacy.
                                </p>)}

                            </div>
                        </div>
                        <div className={styles.ensuringImgContainer}>
                            <img src='../assests/images/OsForMobility/mobile-map.png' alt='mpbile-map' />

                        </div>


                    </div>
                    <div className={styles.yourEmployeeContainer}>
                        <div className={styles.yourEmployeeContent}>
                            <h2>Making your employees' privacy a priority!</h2>
                            <h5>Number Masking</h5>
                            <p className={styles.paragraph}>
                                feature to avoid direct contact between driver & an employee
                            </p>
                            <h5>Data Masking</h5>
                            <p className={styles.paragraph}>
                                to limit ground ops and other teams accessing the employee
                                details
                            </p>
                            <p className={styles.paragraph}>
                                Our platform assigns a Unique Identification Number for every
                                employee using which the stakeholders can identify and
                                communicate without the need for any further details.
                            </p>

                        </div>
                        <div className={styles.imgContainer}>
                            <img src='../assests/images/OsForMobility/your-employee.png' alt='employee'/>
                        </div>
                    </div>
                    <div className={styles.fullControlContainer}>
                        <div className={styles.fullControlContent}>
                            <h2>Full Control over the Transport Operations, at all times!</h2>
                            <div
                                className={styles.hover}
                                style={controlImg === "Live-Tracking" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    // handleControl("LT", "Live -Tracking")
                                    setControlImg("Live-Tracking")

                                }}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${controlImg === "Live -Tracking" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Live Tracking
                                </h3>
                                {controlImg === "Live-Tracking" && (<p className={styles.para}>
                                    The admin teams can have a complete overview of the transport operations and will be able to get real-time updates/alerts in case of issues during the trip.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={controlImg === "Customized-Reports" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setControlImg("Customized-Reports")
                                    // handleControl("CR", "Customized-Reports")
                                }}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${controlImg === "Customized-Reports" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Customized Reports
                                </h3>
                                {controlImg === "Customized-Reports" && (<p className={styles.para}>
                                    The actionable insights will help the admin teams and key stakeholders in making informed decisions. The trip history data along with the play vehicle movement can be viewed.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={controlImg === "Compliance" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    // handleControl("Com", "Compliance")
                                    setControlImg("Compliance")

                                }}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${controlImg === "Compliance" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Compliance
                                </h3>
                                {controlImg === "Compliance" && (<p className={styles.para}>
                                    The stakeholders can keep track of the documents of every driver effortlessly. In addition, we can set reminders of expiring documents that help in maintaining 100% compliance!
                                </p>)}

                            </div>
                        </div>
                        <div className={styles.fullControlImgContainer}>
                            <img src={`../assests/images/OsForMobility/${controlImg}.png`}  alt='os-for-mobility'/>

                        </div>


                    </div>
                    <div className={styles.groundContainer}>
                        <div className={styles.groundContent}>
                            <h2>Making it easy for the Ground Ops!</h2>
                            <div

                                className={styles.hover}
                                style={groundImg === "TripModifications" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={(e) => {
                                    console.log("TripModifications clicked")
                                    e.preventDefault()
                                    // handleControl("LT", "Live -Tracking")
                                    setGroundImg("TripModifications")

                                }}
                                onTouchStart={() => setGroundImg("TripModifications")}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${groundImg === "TripModifications" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Trip Modifications
                                </h3>
                                {groundImg === "TripModifications" && (<p className={styles.para}>
                                    For any last-minute changes! Now the supervisors can create a new trip or modify the planned trips at ease.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={groundImg === "TripAlerts" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={(e) => {
                                    console.log("TripAlerts")
                                    e.preventDefault()
                                    setGroundImg("TripAlerts")
                                    // handleControl("CR", "Customized-Reports")
                                }}
                                onTouchStart={() => setGroundImg("TripAlerts")}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${groundImg === "TripAlerts" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Trip Alerts & Monitoring
                                </h3>
                                {groundImg === "TripAlerts" && (<p className={styles.para}>
                                    Trip alerts give live updates to supervisors on late boardings, cab overspeed, trip delays, escort trips, employee safe drop-offs, and emergency alerts helping them stay informed.
                                </p>)}

                            </div>
                            <div
                                className={styles.hover}
                                style={groundImg === "UploadingCab" ? { backgroundColor: '#f8f9fb' } : {}}
                                onClick={(e) => {
                                    console.log("UploadingCab")
                                    e.preventDefault()
                                    // handleControl("Com", "Compliance")
                                    setGroundImg("UploadingCab")


                                }}
                                onTouchStart={() => setGroundImg("UploadingCab")}
                            >
                                <h3>
                                    <span>
                                        <i className={`fa-solid fa-circle ${groundImg === "UploadingCab" ? styles.activeCircle : styles.circle1}`}></i>
                                    </span>
                                    Uploading Cab & Driver Documents
                                </h3>
                                {groundImg === "UploadingCab" && (<p className={styles.para}>
                                    The supervisors can verify the cab & driver documents and upload them to the platform using their mobile application. And a supervisor also receives alerts in case of nearing dates of document renewals to co-ordinate with the driver partners.
                                </p>)}

                            </div>
                        </div>
                        <div className={styles.groundImgContainer}>
                            <img src={`../assests/images/OsForMobility/${groundImg}.png`} alt='os-for-mobility' />

                        </div>


                    </div>
                    <ContactUsCard img={"ET_and_EL"} text={"Want to know more about our End-to-End Employee Transportation solution?"} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default OsForMobility
